require('./jquery.appear.min');
require('./jquery.easing.min');
require('./jquery.cookie.min');
require('./bootstrap.bundle.min');
require('./owl.carousel.min');
require('./theme');
require('./view.home');
require('./custom');
require('./theme.init');

